import React from 'react';
import styled from 'styled-components';
import logo from '../utilities/logo-2.jpg';

const HeaderStyle = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
`;
const LogoBanner = () => {
  return (
    <HeaderStyle>
        <img src={logo} alt="Bump In The Road logo" style={{width:"5rem"}}/>
    </HeaderStyle>
  );
};

export default LogoBanner;