import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
    border-radius: 25px;
    background-color: #660E99;
    color: white;
    height: 2.8rem;
    font-weight: 700;
    margin: 2rem;

    &:hover {
        background-color: #8627bd;
        color: #fffbd9;
        border-color: #fff185;
    }
`
