import './App.css';
import Main from './components/Main.jsx';
import LogoBanner from './components/LogoBanner';

function App() {
  return (
    <div className="App">
      <LogoBanner />
      <Main />
    </div>
  );
}

export default App;
