import React from 'react';
import styled from 'styled-components';
import {MinusOutlined} from '@ant-design/icons';

const HeaderBox = styled.div`
  text-align: center;
  overflow: hidden;
`;
const TopLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Heading = styled.h2`
  color: #8627bd;
  padding: 1rem;
  padding-bottom: .5rem;
  text-transform: uppercase;
  font-weight: bold;
`
const Subtext = styled.div`
  color: 'red';  
  width: 33vw;
  text-align: center;
`
const Header = (props) => {
  return (
    <HeaderBox>
        <TopLine>
            <MinusOutlined />
            <Heading>{props.main}</Heading>
            <MinusOutlined />
        </TopLine>
        <center>
        <Subtext>{props.sub}</Subtext>
        </center>
    </HeaderBox>
  );
};

export default Header;