import expressionlessFace from './reactions/neutral/expressionless-face_1f611.png';
import faceWithRaisedEyebrow from './reactions/neutral/face-with-raised-eyebrow_1f928.png';
import faceWithThermometer from './reactions/neutral/face-with-thermometer_1f912.png';
import faceWithoutMouth from './reactions/neutral/face-without-mouth_1f636.png';
import neutralFace from './reactions/neutral/neutral-face_1f610.png';
import sleepingFace from './reactions/neutral/sleeping-face_1f634.png';
import sneezingFace from './reactions/neutral/sneezing-face_1f927.png';
import yawningFace from './reactions/neutral/yawning-face_1f971.png';

export const expressionlessFaceObj = {
  img: expressionlessFace,
  name: "expressionless-face"
}
export const faceWithRaisedEyebrowObj = {
  img: faceWithRaisedEyebrow,
  name: "face-with-raised-eyebrow"
}
export const faceWithThermometerObj = {
  img: faceWithThermometer,
  name: "face-with-thermometer"
}
export const faceWithoutMouthObj = {
  img: faceWithoutMouth,
  name: "face-without-mouth"
}
export const neutralFaceObj = {
  img: neutralFace,
  name: "neutral-face"
}
export const sleepingFaceObj = {
  img: sleepingFace,
  name: "sleeping-face"
}
export const sneezingFaceObj = {
  img: sneezingFace,
  name: "sneezing-face"
}
export const yawningFaceObj = {
  img: yawningFace,
  name: "yawning-face"
}

export const neutrals = [
  yawningFaceObj, 
  sneezingFaceObj,
  sleepingFaceObj,
  neutralFaceObj,
  faceWithoutMouthObj,
  faceWithThermometerObj,
  faceWithRaisedEyebrowObj,
  expressionlessFaceObj
]