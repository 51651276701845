import React from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'antd';
import { Select, Radio } from "formik-antd";
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { StyledButton } from './shared/StyledButton';
import Header from './shared/Header';
import { postParticipant } from '../services/apiCalls';

const Box = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
`;

const FormColumn = styled.div`
    margin: 1rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const StyledInputs = styled(Input)`
    margin-bottom: 1rem;
    width: 37vw;
`;

const StyledSelect = styled(Select)`
    margin-bottom: 1rem;
    width: 37vw;
`;

const InfoForm = () => {

    const [cancerStage, setCancerStage] = React.useState(0)
    const [dropDown, setDropDown] = React.useState(<div> </div>)

    const yearOptions = []
    for(var i = 2021; i > 1930; i-- ) {
        yearOptions.push(i)
    }
    const ageOptions = []
    for(var i = 0; i <= 110; i++ ) {
        ageOptions.push(i)
    }

    const callApiWhenSubmit = (values) => {
        postParticipant(JSON.stringify(values, null, 2)).then((response) => {
            history.push("/intro")
        }).catch((err) => {
            console.log("ERROR:", err)
        })
    }

    const generateDropDown = (e) => {
        setCancerStage(e.target.value)
        if (e.target.value > 3) {
            setDropDown(
                <>
                <div style={{ width: '37vw' }}>
                Because you answered stage 4, what was the year your first round of treatment (chemo / surgery / radiation) ended?
                </div>
                <StyledSelect
                    name="yearDiagnosed"
                    placeholder="Select a year"
                    allowClear
                >
                    {yearOptions.map((year, index) => {
                        return <Option key={index} value={`${year}`}>{year}</Option> 
                    })}
                </StyledSelect>
                </>
            )
        } else if (e.target.value <= 3){
            setDropDown(
                <>
                <div style={{ width: '37vw' }}>
                Because you answered stage 1, 2, or 3, what was the year of your last cancer treatment (chemo, surgery, radiation, but not including reconstruction)?</div>
                <StyledSelect
                    name="yearDiagnosed"
                    placeholder="Select a year"
                    allowClear
                >
                    {yearOptions.map((year, index) => {
                        return <Option key={index} value={`${year}`}>{year}</Option> 
                    })}
                </StyledSelect>
                </>
            )
        }
    }

    const history = useHistory();
     
    return (
        <Box>
            <Header 
                main="Make a Difference For Breast Cancer Survival" 
                sub="Register below and we'll email you a link to participate."/>

            <Formik
                initialValues={{ 
                    ageDiagnosed: '',
                    stage: '',
                    yearDiagnosed: '',
                    emailAddress: '', 
                    dateOfBirth: '', 
                    gender: '', 
                    country: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.emailAddress) {
                        errors.emailAddress = 'Required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
                        errors.emailAddress = 'Invalid email address';
                    }
                    if (!values.dateOfBirth) {
                        errors.dateOfBirth = 'Required';
                    }
                    if (!values.gender) {
                        errors.gender = 'Required';
                    }
                    if (!values.country){
                        errors.country = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    callApiWhenSubmit(values)
                    setSubmitting(false);
                }}
            >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Layout>
                        <FormColumn>
                            <p style={{ width: '37vw' }}>Select your age at your breast cancer diagnosis</p>
                            <StyledSelect
                                name="ageAtDiagnosis"
                                placeholder="Age"
                                onChange={handleChange}
                                allowClear
                            >
                                {ageOptions.map((age, index) => {
                                    return <Option key={index} value={`${age}`}>{age}</Option> 
                                })}
                            </StyledSelect>

                            <Form.Item style={{ marginBottom: '1rem', width: '37vw' }}>
                                <p>At what stage was your cancer first diagnosed?</p>
                                <Radio.Group 
                                name="stage"
                                onChange={generateDropDown} 
                                value={cancerStage}
                                >
                                    <Radio value={1}>Stage 1</Radio>
                                    <Radio value={2}>Stage 2</Radio>
                                    <Radio value={3}>Stage 3</Radio>
                                    <Radio value={4}>Stage 4</Radio>
                                </Radio.Group>
                            </Form.Item>
                            
                            {dropDown}

                            <p style={{ width: '37vw' }}>Enter your email address</p>
                            <StyledInputs
                                type="email"
                                name="emailAddress"
                                placeholder="email address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.emailAddress}
                            />
                            {errors.emailAddress && touched.emailAddress && errors.emailAddress}
                            <p style={{ width: '37vw' }}>Date of birth</p>
                            <StyledInputs
                                type="date"
                                name="dateOfBirth"
                                maxLength={10}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dateOfBirth}
                            />
                            {errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
                            <p style={{ width: '37vw' }}>What is your sex?</p>
                            <StyledSelect
                                name="gender"
                                placeholder="Select your sex"
                            >
                                <Option key={'Male'} value={'Male'}>Male</Option> 
                                <Option key={'Female'} value={'Female'}>Female</Option> 
                                <Option key={'Transgender Female'} value={'Transgender Female'}>Transgender Female</Option> 
                                <Option key={'Transgender Male'} value={'Transgender Male'}>Transgender Male</Option> 
                                <Option key={'Other'} value={'Other'}>Other</Option> 
                            </StyledSelect>
                            <p style={{ width: '37vw' }}>What country do you live in?</p>
                            <StyledSelect
                                name="country"
                                placeholder="Select your country"
                            >
                                <Option value="United States">United States</Option>
                                <Option value="Afghanistan">Afghanistan</Option>
                                <Option value="Åland Islands">Åland Islands</Option>
                                <Option value="Albania">Albania</Option>
                                <Option value="Algeria">Algeria</Option>
                                <Option value="American Samoa">American Samoa</Option>
                                <Option value="Andorra">Andorra</Option>
                                <Option value="Angola">Angola</Option>
                                <Option value="Anguilla">Anguilla</Option>
                                <Option value="Antarctica">Antarctica</Option>
                                <Option value="Antigua and Barbuda">Antigua and Barbuda</Option>
                                <Option value="Argentina">Argentina</Option>
                                <Option value="Armenia">Armenia</Option>
                                <Option value="Aruba">Aruba</Option>
                                <Option value="Australia">Australia</Option>
                                <Option value="Austria">Austria</Option>
                                <Option value="Azerbaijan">Azerbaijan</Option>
                                <Option value="Bahamas">Bahamas</Option>
                                <Option value="Bahrain">Bahrain</Option>
                                <Option value="Bangladesh">Bangladesh</Option>
                                <Option value="Barbados">Barbados</Option>
                                <Option value="Belarus">Belarus</Option>
                                <Option value="Belgium">Belgium</Option>
                                <Option value="Belize">Belize</Option>
                                <Option value="Benin">Benin</Option>
                                <Option value="Bermuda">Bermuda</Option>
                                <Option value="Bhutan">Bhutan</Option>
                                <Option value="Bolivia">Bolivia</Option>
                                <Option value="Bosnia and Herzegovina">Bosnia and Herzegovina</Option>
                                <Option value="Botswana">Botswana</Option>
                                <Option value="Bouvet Island">Bouvet Island</Option>
                                <Option value="Brazil">Brazil</Option>
                                <Option value="British Indian Ocean Territory">British Indian Ocean Territory</Option>
                                <Option value="Brunei Darussalam">Brunei Darussalam</Option>
                                <Option value="Bulgaria">Bulgaria</Option>
                                <Option value="Burkina Faso">Burkina Faso</Option>
                                <Option value="Burundi">Burundi</Option>
                                <Option value="Cambodia">Cambodia</Option>
                                <Option value="Cameroon">Cameroon</Option>
                                <Option value="Canada">Canada</Option>
                                <Option value="Cape Verde">Cape Verde</Option>
                                <Option value="Cayman Islands">Cayman Islands</Option>
                                <Option value="Central African Republic">Central African Republic</Option>
                                <Option value="Chad">Chad</Option>
                                <Option value="Chile">Chile</Option>
                                <Option value="China">China</Option>
                                <Option value="Christmas Island">Christmas Island</Option>
                                <Option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</Option>
                                <Option value="Colombia">Colombia</Option>
                                <Option value="Comoros">Comoros</Option>
                                <Option value="Congo">Congo</Option>
                                <Option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</Option>
                                <Option value="Cook Islands">Cook Islands</Option>
                                <Option value="Costa Rica">Costa Rica</Option>
                                <Option value="Cote D'ivoire">Cote D'ivoire</Option>
                                <Option value="Croatia">Croatia</Option>
                                <Option value="Cuba">Cuba</Option>
                                <Option value="Cyprus">Cyprus</Option>
                                <Option value="Czech Republic">Czech Republic</Option>
                                <Option value="Denmark">Denmark</Option>
                                <Option value="Djibouti">Djibouti</Option>
                                <Option value="Dominica">Dominica</Option>
                                <Option value="Dominican Republic">Dominican Republic</Option>
                                <Option value="Ecuador">Ecuador</Option>
                                <Option value="Egypt">Egypt</Option>
                                <Option value="El Salvador">El Salvador</Option>
                                <Option value="Equatorial Guinea">Equatorial Guinea</Option>
                                <Option value="Eritrea">Eritrea</Option>
                                <Option value="Estonia">Estonia</Option>
                                <Option value="Ethiopia">Ethiopia</Option>
                                <Option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</Option>
                                <Option value="Faroe Islands">Faroe Islands</Option>
                                <Option value="Fiji">Fiji</Option>
                                <Option value="Finland">Finland</Option>
                                <Option value="France">France</Option>
                                <Option value="French Guiana">French Guiana</Option>
                                <Option value="French Polynesia">French Polynesia</Option>
                                <Option value="French Southern Territories">French Southern Territories</Option>
                                <Option value="Gabon">Gabon</Option>
                                <Option value="Gambia">Gambia</Option>
                                <Option value="Georgia">Georgia</Option>
                                <Option value="Germany">Germany</Option>
                                <Option value="Ghana">Ghana</Option>
                                <Option value="Gibraltar">Gibraltar</Option>
                                <Option value="Greece">Greece</Option>
                                <Option value="Greenland">Greenland</Option>
                                <Option value="Grenada">Grenada</Option>
                                <Option value="Guadeloupe">Guadeloupe</Option>
                                <Option value="Guam">Guam</Option>
                                <Option value="Guatemala">Guatemala</Option>
                                <Option value="Guernsey">Guernsey</Option>
                                <Option value="Guinea">Guinea</Option>
                                <Option value="Guinea-bissau">Guinea-bissau</Option>
                                <Option value="Guyana">Guyana</Option>
                                <Option value="Haiti">Haiti</Option>
                                <Option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</Option>
                                <Option value="Holy See (Vatican City State)">Holy See (Vatican City State)</Option>
                                <Option value="Honduras">Honduras</Option>
                                <Option value="Hong Kong">Hong Kong</Option>
                                <Option value="Hungary">Hungary</Option>
                                <Option value="Iceland">Iceland</Option>
                                <Option value="India">India</Option>
                                <Option value="Indonesia">Indonesia</Option>
                                <Option value="Iran, Islamic Republic of">Iran, Islamic Republic of</Option>
                                <Option value="Iraq">Iraq</Option>
                                <Option value="Ireland">Ireland</Option>
                                <Option value="Isle of Man">Isle of Man</Option>
                                <Option value="Israel">Israel</Option>
                                <Option value="Italy">Italy</Option>
                                <Option value="Jamaica">Jamaica</Option>
                                <Option value="Japan">Japan</Option>
                                <Option value="Jersey">Jersey</Option>
                                <Option value="Jordan">Jordan</Option>
                                <Option value="Kazakhstan">Kazakhstan</Option>
                                <Option value="Kenya">Kenya</Option>
                                <Option value="Kiribati">Kiribati</Option>
                                <Option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</Option>
                                <Option value="Korea, Republic of">Korea, Republic of</Option>
                                <Option value="Kuwait">Kuwait</Option>
                                <Option value="Kyrgyzstan">Kyrgyzstan</Option>
                                <Option value="Lao People's Democratic Republic">Lao People's Democratic Republic</Option>
                                <Option value="Latvia">Latvia</Option>
                                <Option value="Lebanon">Lebanon</Option>
                                <Option value="Lesotho">Lesotho</Option>
                                <Option value="Liberia">Liberia</Option>
                                <Option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</Option>
                                <Option value="Liechtenstein">Liechtenstein</Option>
                                <Option value="Lithuania">Lithuania</Option>
                                <Option value="Luxembourg">Luxembourg</Option>
                                <Option value="Macao">Macao</Option>
                                <Option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</Option>
                                <Option value="Madagascar">Madagascar</Option>
                                <Option value="Malawi">Malawi</Option>
                                <Option value="Malaysia">Malaysia</Option>
                                <Option value="Maldives">Maldives</Option>
                                <Option value="Mali">Mali</Option>
                                <Option value="Malta">Malta</Option>
                                <Option value="Marshall Islands">Marshall Islands</Option>
                                <Option value="Martinique">Martinique</Option>
                                <Option value="Mauritania">Mauritania</Option>
                                <Option value="Mauritius">Mauritius</Option>
                                <Option value="Mayotte">Mayotte</Option>
                                <Option value="Mexico">Mexico</Option>
                                <Option value="Micronesia, Federated States of">Micronesia, Federated States of</Option>
                                <Option value="Moldova, Republic of">Moldova, Republic of</Option>
                                <Option value="Monaco">Monaco</Option>
                                <Option value="Mongolia">Mongolia</Option>
                                <Option value="Montenegro">Montenegro</Option>
                                <Option value="Montserrat">Montserrat</Option>
                                <Option value="Morocco">Morocco</Option>
                                <Option value="Mozambique">Mozambique</Option>
                                <Option value="Myanmar">Myanmar</Option>
                                <Option value="Namibia">Namibia</Option>
                                <Option value="Nauru">Nauru</Option>
                                <Option value="Nepal">Nepal</Option>
                                <Option value="Netherlands">Netherlands</Option>
                                <Option value="Netherlands Antilles">Netherlands Antilles</Option>
                                <Option value="New Caledonia">New Caledonia</Option>
                                <Option value="New Zealand">New Zealand</Option>
                                <Option value="Nicaragua">Nicaragua</Option>
                                <Option value="Niger">Niger</Option>
                                <Option value="Nigeria">Nigeria</Option>
                                <Option value="Niue">Niue</Option>
                                <Option value="Norfolk Island">Norfolk Island</Option>
                                <Option value="Northern Mariana Islands">Northern Mariana Islands</Option>
                                <Option value="Norway">Norway</Option>
                                <Option value="Oman">Oman</Option>
                                <Option value="Pakistan">Pakistan</Option>
                                <Option value="Palau">Palau</Option>
                                <Option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</Option>
                                <Option value="Panama">Panama</Option>
                                <Option value="Papua New Guinea">Papua New Guinea</Option>
                                <Option value="Paraguay">Paraguay</Option>
                                <Option value="Peru">Peru</Option>
                                <Option value="Philippines">Philippines</Option>
                                <Option value="Pitcairn">Pitcairn</Option>
                                <Option value="Poland">Poland</Option>
                                <Option value="Portugal">Portugal</Option>
                                <Option value="Puerto Rico">Puerto Rico</Option>
                                <Option value="Qatar">Qatar</Option>
                                <Option value="Reunion">Reunion</Option>
                                <Option value="Romania">Romania</Option>
                                <Option value="Russian Federation">Russian Federation</Option>
                                <Option value="Rwanda">Rwanda</Option>
                                <Option value="Saint Helena">Saint Helena</Option>
                                <Option value="Saint Kitts and Nevis">Saint Kitts and Nevis</Option>
                                <Option value="Saint Lucia">Saint Lucia</Option>
                                <Option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</Option>
                                <Option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</Option>
                                <Option value="Samoa">Samoa</Option>
                                <Option value="San Marino">San Marino</Option>
                                <Option value="Sao Tome and Principe">Sao Tome and Principe</Option>
                                <Option value="Saudi Arabia">Saudi Arabia</Option>
                                <Option value="Senegal">Senegal</Option>
                                <Option value="Serbia">Serbia</Option>
                                <Option value="Seychelles">Seychelles</Option>
                                <Option value="Sierra Leone">Sierra Leone</Option>
                                <Option value="Singapore">Singapore</Option>
                                <Option value="Slovakia">Slovakia</Option>
                                <Option value="Slovenia">Slovenia</Option>
                                <Option value="Solomon Islands">Solomon Islands</Option>
                                <Option value="Somalia">Somalia</Option>
                                <Option value="South Africa">South Africa</Option>
                                <Option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</Option>
                                <Option value="Spain">Spain</Option>
                                <Option value="Sri Lanka">Sri Lanka</Option>
                                <Option value="Sudan">Sudan</Option>
                                <Option value="Suriname">Suriname</Option>
                                <Option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</Option>
                                <Option value="Swaziland">Swaziland</Option>
                                <Option value="Sweden">Sweden</Option>
                                <Option value="Switzerland">Switzerland</Option>
                                <Option value="Syrian Arab Republic">Syrian Arab Republic</Option>
                                <Option value="Taiwan">Taiwan</Option>
                                <Option value="Tajikistan">Tajikistan</Option>
                                <Option value="Tanzania, United Republic of">Tanzania, United Republic of</Option>
                                <Option value="Thailand">Thailand</Option>
                                <Option value="Timor-leste">Timor-leste</Option>
                                <Option value="Togo">Togo</Option>
                                <Option value="Tokelau">Tokelau</Option>
                                <Option value="Tonga">Tonga</Option>
                                <Option value="Trinidad and Tobago">Trinidad and Tobago</Option>
                                <Option value="Tunisia">Tunisia</Option>
                                <Option value="Turkey">Turkey</Option>
                                <Option value="Turkmenistan">Turkmenistan</Option>
                                <Option value="Turks and Caicos Islands">Turks and Caicos Islands</Option>
                                <Option value="Tuvalu">Tuvalu</Option>
                                <Option value="Uganda">Uganda</Option>
                                <Option value="Ukraine">Ukraine</Option>
                                <Option value="United Arab Emirates">United Arab Emirates</Option>
                                <Option value="United Kingdom">United Kingdom</Option>
                                <Option value="United States Minor Outlying Islands">United States Minor Outlying Islands</Option>
                                <Option value="Uruguay">Uruguay</Option>
                                <Option value="Uzbekistan">Uzbekistan</Option>
                                <Option value="Vanuatu">Vanuatu</Option>
                                <Option value="Venezuela">Venezuela</Option>
                                <Option value="Viet Nam">Viet Nam</Option>
                                <Option value="Virgin Islands, British">Virgin Islands, British</Option>
                                <Option value="Virgin Islands, U.S.">Virgin Islands, U.S.</Option>
                                <Option value="Wallis and Futuna">Wallis and Futuna</Option>
                                <Option value="Western Sahara">Western Sahara</Option>
                                <Option value="Yemen">Yemen</Option>
                                <Option value="Zambia">Zambia</Option>
                                <Option value="Zimbabwe">Zimbabwe</Option>                            </StyledSelect>
                        </FormColumn>
                    </Layout>
                    <StyledButton htmlType="submit" disabled={isSubmitting}>
                        SEND ME A LINK
                    </StyledButton>
                </form>
            )}
            </Formik>

        </Box>
    )
}

export default InfoForm;