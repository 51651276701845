import React, {useRef} from 'react';
import Header from './shared/Header';
import ReactionSelect from './ReactionSelect';
import ReactPlayer from 'react-player';
import getWindowsDimensions from '../utilities/getWindowsDimensions';
import { StyledButton } from './shared/StyledButton';
import styled from 'styled-components'
import { postResponse } from '../services/apiCalls';
import { useLocation } from "react-router-dom";
import { postCompletion } from '../services/apiCalls';

//To Do:
// make video size responsive
// track emotes in reactionsArray to display below selector
// route to info graph of reactions
// create info graph of all user reactions

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
`;

const ReactionsStudyPage = () => {
    //get our query string
    let query = new URLSearchParams(useLocation().search);

    const [reactionsArray, setReactionsArray] = React.useState([])
    const player = useRef(null);
    const { width } = getWindowsDimensions()

    const handleReactionClick = (unicode) => {
        const currentTime = player.current.getCurrentTime();
        //create the object using the id that was passed in on the querystring
        const reactionObject = {
            participantId: query.get("id"),
            videoURL: "https://youtu.be/gtITIzxaBrc",
            seconds: currentTime, 
            emoji: unicode,
        }
        postResponse(reactionObject).then((response) => {
            setReactionsArray(unicode) //<- sets to display at bottom of screen
        }).catch((err) => {
            console.log("ERROR POSTING REACTION:    ", err)
        })
    }
    
    const [lowerContent, setLowerContent] = React.useState(<ReactionSelect handleReactionClick={handleReactionClick} />)

    const switchContent = (status) => {
        //did it end?
        //yes
        if (status === "ended"){
            setLowerContent(
                <div style={{ paddingTop: 30 }}>Thank you for your participation!</div>
            );
            const completionObject = {
                participantId: query.get("id"),
            }
            postCompletion(completionObject);
        } 
        //did it end?
        //no - started
        else if (status === "started") {
            setLowerContent(
                <ReactionSelect handleReactionClick={handleReactionClick} />
            )
        }
    }
    return(
        <Box>
            <Header
                main="SHARE YOUR FEELINGS DURING THIS VIDEO" 
                sub=""
            />
            
            <ReactPlayer 
                width={width <= 640 ? "100%" : "640px"} //closest I got to responsive video sizing
                height={width <= 640 ? "100%" : "360px"}
                ref={player} //wont work on a styled(ReactPlayer) component
                controls={true}
                onEnded={() => switchContent("ended")}
                onPlay={() => switchContent("started")}
                url="https://youtu.be/gtITIzxaBrc"/>
                
            {lowerContent}
        </Box>
    )
}

export default ReactionsStudyPage