import axios from "axios";

export const postParticipant = (data) => {
  //let url = "http://localhost:7071/api/bumpintheroad/participant";
  let url = "https://mindfulappy.azurewebsites.net/api/bumpintheroad/participant";

  return axios.post(url, data, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}

export const postResponse = (data) => {
  //let url = "http://localhost:7071/api/bumpintheroad/response";
  let url = "https://mindfulappy.azurewebsites.net/api/bumpintheroad/response";

  return axios.post(url, data, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}

export const postCompletion = (data) => {
  //let url = "http://localhost:7071/api/bumpintheroad/response";
  let url = "https://mindfulappy.azurewebsites.net/api/bumpintheroad/completion";
  
  return axios.post(url, data, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}