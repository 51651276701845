import React from 'react';
import VideoPlayer from './VideoPlayer';
import Header from './shared/Header';
import styled from 'styled-components'

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
    overflow: hidden;
`;

const WordContent = styled.div`
    margin-top: 3rem;
    font-size: 28px;
`;

const IntroVideoPage = () => {
    return(
        <Box>
            <Header 
                main="Thank you for making a difference for breast cancer survival"
            />
            <WordContent style={{ width: '35vw' }}>
                <p>We've received your registration.</p>
                <p>Look for an email from pat@mindfulappy.com (be sure to check your spam folder!).</p>
                <p>The study will take about 5 minutes!</p>
            </WordContent>
        </Box>
    )
}

export default IntroVideoPage