import React from 'react';
import InfoForm from './InfoForm'
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import IntroVideoPage from './IntroVideoPage';
import VideoPage from './VideoPage';
import ReactionsStudyPage from './ReactionsStudyPage';

const Main = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/intro">
            <IntroVideoPage />
          </Route>
          <Route path="/study">
            <ReactionsStudyPage />
          </Route>
          <Route path="/video">
            <VideoPage />
          </Route>
          <Route path="/">
            <InfoForm />
          </Route>
        </Switch>
    </Router>
    </>
  );
};

export default Main;
