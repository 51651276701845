import React from 'react';
import VideoPlayer from './VideoPlayer';
import Header from './shared/Header';
import styled from 'styled-components'
import { useLocation } from "react-router-dom";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
    overflow: hidden;
`;

const WordContent = styled.div`
    margin-top: 3rem;
    font-size: 28px;
`;

const VideoPage = () => {
    //get our query string
    let query = new URLSearchParams(useLocation().search);
    return(
        <Box>
            <Header 
                main="Thank you for making a difference for breast cancer survival"
            />
            <VideoPlayer 
                url='https://www.youtube.com/watch?v=yR4DxSFuIjg'
            />
            <WordContent style={{ width: '35vw' }}>
                <a href={"./study?id=" + query.get("id")}>Click here to get started</a>
            </WordContent>
        </Box>
    )
}

export default VideoPage