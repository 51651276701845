import React from 'react';
import styled from 'styled-components'
import { negatives } from '../utilities/negativeReactionObjs.jsx';
import { neutrals } from '../utilities/neutralReactrionObjs.jsx';
import { positives } from '../utilities/positiveReactionsObjs.jsx'
import { message } from 'antd';

const Box = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledImg = styled.img`
    width: 2.5rem;
`;

const EmojiSect = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(10px, auto);
    margin: 1rem;
    margin-left: -.2rem;
    margin-right: -.2rem;
`;

const EmojiSect2 = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(10px, auto);
    margin: 1rem;
    padding-left: .8rem;
    padding-right: .8rem;
    border-left: 1px solid black;
    border-right: 1px solid black;
`;

const ReactionSelect = (props) => {

    let emotions = [];

    const sendReaction = (img) => {
        props.handleReactionClick(img.name);
        emotions.push(img.img);
        message.success('We recorded your feeling');
    }

    return (
        <>
            <div style={{ paddingTop: 10, width: '33vw', color: '#660E99', textAlign: 'left', fontWeight: 'bold', fontSize: '16pt'  }}>HOW I FEEL ABOUT THIS MOMENT:</div>
            <Box>
                <EmojiSect>
                    {positives.map((img, index) => {
                    return  <StyledImg 
                                key={index} 
                                src={img.img} 
                                alt={img.name} 
                                onClick={() => sendReaction(img)}
                            />
                    })}
                </EmojiSect>
                <EmojiSect2>
                {neutrals.map((img, index) => {
                    return  <StyledImg 
                                key={index} 
                                src={img.img} 
                                alt={img.name} 
                                onClick={() => sendReaction(img)}
                            />
                    })}
                </EmojiSect2>
                <EmojiSect>
                    {negatives.map((img, index) => {
                    return  <StyledImg 
                                key={index} 
                                src={img.img} 
                                alt={img.name} 
                                onClick={() => sendReaction(img)}
                            />
                    })}
                </EmojiSect>
            </Box>
        </>
    )
}

export default ReactionSelect