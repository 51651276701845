import astonishedFace from './reactions/positive/astonished-face_1f632.png'
import beamingFaceWithSmilingEyes from './reactions/positive/beaming-face-with-smiling-eyes_1f601.png'
import droolingFace from './reactions/positive/drooling-face_1f924.png'
import faceWithOpenMouth from './reactions/positive/face-with-open-mouth_1f62e.png'
import faceWithTearsOfJoy from './reactions/positive/face-with-tears-of-joy_1f602.png'
import grinningFace from './reactions/positive/grinning-face_1f600.png'
import grinningFaceWithBigEyes from './reactions/positive/grinning-face-with-big-eyes_1f603.png'
import grinningFaceWithSweat from './reactions/positive/grinning-face-with-sweat_1f605.png'
import grinningSquintingFace from './reactions/positive/grinning-squinting-face_1f606.png'
import huggingFace from './reactions/positive/hugging-face_1f917.png'
import partyingFace from './reactions/positive/partying-face_1f973.png'
import relievedFace from './reactions/positive/relieved-face_1f60c.png'
import rollingOnTheFloorLaughing from './reactions/positive/rolling-on-the-floor-laughing_1f923.png'
import slightlySmilingFace from './reactions/positive/slightly-smiling-face_1f642.png'
import smilingFaceWithHalo from './reactions/positive/smiling-face-with-halo_1f607.png'
import smilingFaceWithHeartEyes from './reactions/positive/smiling-face-with-heart-eyes_1f60d.png'
import smilingFaceWithHearts from './reactions/positive/smiling-face-with-hearts_1f970.png'
import smilingFaceWithSmilingEyes from './reactions/positive/smiling-face-with-smiling-eyes_1f60a.png'
import winkingFace from './reactions/positive/winking-face_1f609.png'
import winkingFaceWithTongue from './reactions/positive/winking-face-with-tongue_1f61c.png'

export const astonishedFaceObj = {
  img: astonishedFace,
  name: ":astonished:"
}
export const beamingFaceWithSmilingEyesObj = {
  img: beamingFaceWithSmilingEyes,
  name: "beaming-face-with-smiling-eyes"
}
export const droolingFaceObj = {
  img: droolingFace,
  name: "drooling-face"
}
export const faceWithOpenMouthObj = {
  img: faceWithOpenMouth,
  name: "face-with-open-mouth"
}
export const faceWithTearsOfJoyObj = {
  img: faceWithTearsOfJoy,
  name: "face-with-tears-of-joy"
}
export const grinningFaceObj = {
  img: grinningFace,
  name: "grinning-face"
}
export const grinningFaceWithBigEyesObj = {
  img: grinningFaceWithBigEyes,
  name: "grinning-face-with-big-eyes"
}
export const grinningFaceWithSweatObj = {
  img: grinningFaceWithSweat,
  name: "grinning-face-with-sweat"
}
export const grinningSquintingFaceObj = {
  img: grinningSquintingFace,
  name: "grinning-squinting-face"
}
export const huggingFaceObj = {
  img: huggingFace,
  name: "hugging-face"
}
export const partyingFaceObj = {
  img: partyingFace,
  name: "partying-face"
}
export const relievedFaceObj = {
  img: relievedFace,
  name: "relieved-face"
}
export const rollingOnTheFloorLaughingObj = {
  img: rollingOnTheFloorLaughing,
  name: "rolling-on-the-floor-laughing"
}
export const slightlySmilingFaceObj = {
  img: slightlySmilingFace,
  name: "slightly-smiling-face"
}
export const smilingFaceWithHaloObj = {
  img: smilingFaceWithHalo,
  name: "smiling-face-with-halo"
}
export const smilingFaceWithHeartEyesObj = {
  img: smilingFaceWithHeartEyes,
  name: "smiling-face-with-heart-eyes"
}
export const smilingFaceWithHeartsObj = {
  img: smilingFaceWithHearts,
  name: "smiling-face-with-hearts"
}
export const smilingFaceWithSmilingEyesObj = {
  img: smilingFaceWithSmilingEyes,
  name: "smiling-face-with-smiling-eyes"
}
export const winkingFaceObj = {
  img: winkingFace,
  name: "winking-face"
}
export const winkingFaceWithTongueObj = {
  img: winkingFaceWithTongue,
  name: "winking-face-with-tongue"
}


export const positives = [
  astonishedFaceObj, 
  beamingFaceWithSmilingEyesObj,
  droolingFaceObj,
  faceWithOpenMouthObj,
  faceWithTearsOfJoyObj,
  grinningFaceObj,
  grinningFaceWithBigEyesObj,
  grinningFaceWithSweatObj,
  grinningSquintingFaceObj,
  huggingFaceObj,
  partyingFaceObj,
  relievedFaceObj,
  rollingOnTheFloorLaughingObj,
  slightlySmilingFaceObj,
  smilingFaceWithHaloObj,
  smilingFaceWithHeartEyesObj,
  smilingFaceWithHeartsObj,
  smilingFaceWithSmilingEyesObj,
  winkingFaceObj,
  winkingFaceWithTongueObj
]
