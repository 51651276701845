import React from 'react';
import ReactPlayer from 'react-player';
import getWindowsDimensions from '../utilities/getWindowsDimensions';

const VideoPlayer = (props) => {
    const { width } = getWindowsDimensions()
    return(
        <>
            <ReactPlayer 
                width={width <= 640 ? "100%" : "640px"}
                height={width <= 640 ? "100%" : "360px"}
                controls={false}
                url={props.url} 
            />
        </>
    )
}

export default VideoPlayer