import angryFace from './reactions/negative/angry-face_1f620.png';
import anguishedFace from './reactions/negative/anguished-face_1f627.png';
import anxiousFaceWithSweat from './reactions/negative/anxious-face-with-sweat_1f630.png';
import confoundedFace from './reactions/negative/confounded-face_1f616.png';
import confusedFace from './reactions/negative/confused-face_1f615.png';
import explodingHead from './reactions/negative/exploding-head_1f92f.png';
import faceScreamingInFear from './reactions/negative/face-screaming-in-fear_1f631.png';
import faceVomiting from './reactions/negative/face-vomiting_1f92e.png';
import faceWithMonocle from './reactions/negative/face-with-monocle_1f9d0.png';
import fearfulFace from './reactions/negative/fearful-face_1f628.png';
import flushedFace from './reactions/negative/flushed-face_1f633.png';
import frowningFace from './reactions/negative/frowning-face_2639-fe0f.png';
import grimacingFace from './reactions/negative/grimacing-face_1f62c.png';
import nauseatedFace from './reactions/negative/nauseated-face_1f922.png';
import pensiveFace from './reactions/negative/pensive-face_1f614.png';
import pleadingFace from './reactions/negative/pleading-face_1f97a.png';
import poutingFace from './reactions/negative/pouting-face_1f621.png';
import slightlyFrowningFace from './reactions/negative/slightly-frowning-face_1f641.png';
import thinkingFace from './reactions/negative/thinking-face_1f914.png';
import tiredFace from './reactions/negative/tired-face_1f62b.png';

export const angryFaceObj = {
  img: angryFace,
  name: "angry-face"
}
export const anguishedFaceObj = {
  img: anguishedFace,
  name: "anguished-face"
}
export const anxiousFaceWithSweatObj = {
  img: anxiousFaceWithSweat,
  name: "anxious-face-with-sweat"
}
export const confoundedFaceObj = {
  img: confoundedFace,
  name: "confounded-face"
}
export const confusedFaceObj = {
  img: confusedFace,
  name: "confused-face"
}
export const explodingHeadObj = {
  img: explodingHead,
  name: "exploding-head"
}
export const faceScreamingInFearObj = {
  img: faceScreamingInFear,
  name: "face-screaming-in-fear"
}
export const faceVomitingObj = {
  img: faceVomiting,
  name: "face-vomiting"
}
export const faceWithMonocleObj = {
  img: faceWithMonocle,
  name: "face-with-monocle"
}
export const fearfulFaceObj = {
  img: fearfulFace,
  name: "fearful-face"
}
export const flushedFaceObj = {
  img: flushedFace,
  name: "flushed-face"
}
export const frowningFaceObj = {
  img: frowningFace,
  name: "frowning-face"
}
export const grimacingFaceObj = {
  img: grimacingFace,
  name: "grimacing-face"
}
export const nauseatedFaceObj = {
  img: nauseatedFace,
  name: "nauseated-face"
}
export const pensiveFaceObj = {
  img: pensiveFace,
  name: "pensive-face"
}
export const pleadingFaceObj = {
  img: pleadingFace,
  name: "pleading-face"
}
export const poutingFaceObj = {
  img: poutingFace,
  name: "pouting-face"
}
export const slightlyFrowningFaceObj = {
  img: slightlyFrowningFace,
  name: "slightly-frowning-face"
}
export const thinkingFaceObj = {
  img: thinkingFace,
  name: "thinking-face"
}
export const tiredFaceObj = {
  img: tiredFace,
  name: "tired-face"
}

export const negatives = [
  angryFaceObj, 
  tiredFaceObj,
  thinkingFaceObj,
  slightlyFrowningFaceObj,
  poutingFaceObj,
  pleadingFaceObj,
  pensiveFaceObj,
  nauseatedFaceObj,
  grimacingFaceObj,
  frowningFaceObj,
  flushedFaceObj,
  fearfulFaceObj,
  faceWithMonocleObj,
  faceVomitingObj,
  faceScreamingInFearObj,
  explodingHeadObj,
  confusedFaceObj,
  confoundedFaceObj,
  anxiousFaceWithSweatObj,
  anguishedFaceObj
]
